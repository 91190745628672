<template>
	<div id="app" class="hotdog">
		<c-app-insights />
		<section class="hotdog-form flex flex--align">
			<img :src="hotdogMan" class="hotdog-form__illustration b6:show" alt="blue hot dog illustration" />
			<div class="hotdog-form__container flex--no-shrink over">
				<div class="hotdog-form__stripes"></div>
				<span>
					<h1>
						<span class="hotdog-form__text-top flex flex--align">
							<svg width="119" height="28" viewBox="0 0 119 28" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M14.0849 0.778809L17.055 9.91971L26.6663 9.91971L18.8906 15.5691L21.8606 24.71L14.0849 19.0606L6.3092 24.71L9.27926 15.5691L1.50354 9.91971L11.1149 9.91971L14.0849 0.778809Z" fill="#001063" />
								<path d="M59.5426 0.778809L62.5126 9.91971L72.1239 9.91971L64.3482 15.5691L67.3183 24.71L59.5426 19.0606L51.7668 24.71L54.7369 15.5691L46.9612 9.91971L56.5725 9.91971L59.5426 0.778809Z" fill="#001063" />
								<path d="M105 0.778809L107.97 9.91971L117.582 9.91971L109.806 15.5691L112.776 24.71L105 19.0606L97.2246 24.71L100.195 15.5691L92.419 9.91971L102.03 9.91971L105 0.778809Z" fill="#001063" />
							</svg>
							<div class="px-3">The</div>
							<svg width="119" height="28" viewBox="0 0 119 28" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M14.0849 0.778809L17.055 9.91971L26.6663 9.91971L18.8906 15.5691L21.8606 24.71L14.0849 19.0606L6.3092 24.71L9.27926 15.5691L1.50354 9.91971L11.1149 9.91971L14.0849 0.778809Z" fill="#001063" />
								<path d="M59.5426 0.778809L62.5126 9.91971L72.1239 9.91971L64.3482 15.5691L67.3183 24.71L59.5426 19.0606L51.7668 24.71L54.7369 15.5691L46.9612 9.91971L56.5725 9.91971L59.5426 0.778809Z" fill="#001063" />
								<path d="M105 0.778809L107.97 9.91971L117.582 9.91971L109.806 15.5691L112.776 24.71L105 19.0606L97.2246 24.71L100.195 15.5691L92.419 9.91971L102.03 9.91971L105 0.778809Z" fill="#001063" />
							</svg>
						</span>
						<span class="hotdog-form__text-middle">Great Hot&nbsp;Dog</span>
						<span class="hotdog-form__text-bottom">Debate</span>
					</h1>
					<c-cognito-form id="282" :cognito-styles="false" class="center over" />
				</span>
				<div class="hotdog-form__stripes hotdog-form__stripes--gray b6:show"></div>
			</div>

			<img :src="sandwichMan" class="hotdog-form__illustration hotdog-form__illustration--sandwich b6:show" alt="blue sandwich illustration" />
		</section>

		<div class="hotdog-cognito text-center">
			<svg class="hotdog-cognito__circle" width="104" height="102" viewBox="0 0 104 102" fill="none" xmlns="http://www.w3.org/2000/svg">
				<circle cx="52" cy="52" r="50" transform="rotate(180 52 52)" fill="white" />
				<path d="M103 52C103 38.474 97.6268 25.5019 88.0624 15.9376C78.4981 6.3732 65.526 0.999999 52 1C38.474 1 25.5019 6.3732 15.9376 15.9376C6.3732 25.5019 1 38.474 1 52" stroke="#001063" stroke-width="2" />
			</svg>

			<router-link to="/"><inline svg="logos/stacked-logo" class="hotdog-cognito__logo block center" alt="Cognito Forms" /></router-link>

			<p class="hotdog-cognito__powered sz-2">Powered by Cognito Forms</p>

			<div class="hotdog-form__little-svg flex b6:hide">
				<img :src="hotdogMan" class="hotdog-form__illustration hotdog-form__illustration" alt="blue hot dog illustration" />
				<img :src="sandwichMan" class="hotdog-form__illustration hotdog-form__illustration--small" alt="blue sandwich illustration" />
			</div>
		</div>
	</div>
</template>

<script>

	import { injectBing } from '@cognitoforms/shared-components/src/layouts/bing';

	import CAppInsights from '@cognitoforms/shared-components/src/components/embeds/AppInsights';

	import { isPrerender } from '@cognitoforms/utils/utilities';

	import CCognitoForm from '@cognitoforms/shared-components/src/components/embeds/CognitoForm.vue';

	export default {

		components: {
			CCognitoForm,
			CAppInsights
		},

		data() {
			return {
				title: 'The Great Hotdog Debate',
				description: 'Let\'s settle this: Is a hot dog a sandwich? Vote now in the ultimate debate and let your voice be heard!',
				canonicalPath: 'l/great-hot-dog-debate'
			};
		},

		head() {
			return {
				title: this.title,
				titleTemplate: '%s',
				meta: [
					{ name: 'description', content: this.description, id: 'desc' },
					{ property: 'og:image', content: this.socialImage, id: 'og:image' },
					{ property: 'twitter:image', content: this.socialImage, id: 'twitter:image' },
					{ property: 'og:title', content: this.title, id: 'og:title' },
					{ property: 'twitter:title', content: this.title, id: 'twitter:title' },
					{ property: 'og:description', content: this.description, id: 'og:description' },
					{ property: 'twitter:description', content: this.description, id: 'twitter:description' }
				],
				link: [
					{ rel: 'canonical', href: 'https://www.cognitoforms.com/' + this.canonicalPath, id: 'canonical' }
				]
			};
		},

		computed: {
			isPrerendered() {
				return isPrerender();
			},
			sandwichMan() {
				return require('@cognitoforms/shared-components/src/assets/graphics/images/sandwich.svg');
			},
			hotdogMan() {
				return require('@cognitoforms/shared-components/src/assets/graphics/images/hotdog.svg');
			},
			socialImage() {
				return require('@cognitoforms/shared-components/src/assets/graphics/images/og-hotdog.jpg');
			}
		},

		mounted() {
			if (!this.isPrerendered) {
				injectBing();
			}
		}
	};
</script>

<style lang="scss" src="@cognitoforms/styles/website/common/utility.scss"></style>
<style lang="scss" src="@cognitoforms/styles/website/common/common.scss"></style>

<style lang="scss">
.hotdog {
	--blue: #001063;
	--red: #a60000;
	align-items: center;
	justify-content: center;
	font-family: Lato, sans-serif;

	body:has(&) {
		background-color: #f5fcff;
	}

	:root &-form {
		justify-content: center;
		gap: 25px;
		margin-bottom: 50px;

		&__stripes {
			height: 6vh;
			background-image:
			linear-gradient(
				to right,
				var(--red),
				var(--red) 50%,
				transparent 50%,
				transparent
			);
			background-size: 18.2%;
		}

		&__stripes--gray {
			position: absolute;
			left: 0;
			right: 0;
			height: 20vh;
			background-image:
		linear-gradient(
				to right,
				var(--blue),
				var(--blue) 50%,
				transparent 50%,
				transparent
			);
			margin-top: 50px;
			opacity: .05;

			@media (max-height: 850px) {
				display: none;
			}

			@media (min-height: 1060px) {
				bottom: -70%;
			}
		}

		h1 {
			width: calc(100% + 20px);
			margin-top: 8vh;
			margin-left: -10px;
			color: var(--blue);
			font-size: 37px;
			line-height: .25em;
			text-align: center;
			text-transform: uppercase;
		}

		&__illustration {
			width: 23%;
			max-width: 300px;

			&--sandwich {
				margin-top: 85px;
			}

			&--small {
				margin-top: 33px;
			}
		}

		&__little-svg {
			position: absolute;
			bottom: 125px;
			justify-content: space-around;
			width: 100%;
			max-height: 110px;
			gap: 100px;

			@media (max-height: 880px) {
				position: relative;
				bottom: 193%;
			}
		}

		&__text {

			&-top {
				width: calc(100% - 20px);
				margin-top: -30px;
				margin-right: auto;
				margin-left: auto;
				font-size: .65em;
				line-height: 2.3em;
			}

			&-bottom {
				font-size: 2.2em;
				line-height: 1.2em;
			}
		}

		&__container {
			width: 290px;
			margin-bottom: 65px;
		}

		&__button {
			background: $deep;
		}

		.cognito-form {
			border: 2px solid var(--blue);
			padding: $space__5 0;

			.cog-form {
				--font-family: 'Gilroy';
				--label__font-family: 'Gilroy';
			}
		}

		/* stylelint-disable-next-line selector-max-specificity */
		#embedded-cognito-form {
			background-color: white;
			/* stylelint-disable-next-line selector-max-specificity */
			.cog-form {
				text-align: center;

				--heading__font-family: 'Gilroy';
				--heading__color: var(--blue);
				--font-family: 'Lato';
				--color: var(--blue);
			}
			/* stylelint-disable-next-line selector-max-specificity */
			.cog-page__navigation {
				justify-content: center;
			}

			/* stylelint-disable-next-line selector-max-specificity */
			.cog-confirmation__message {
				padding: 15px;
				font-size: 20px;
			}

			/* stylelint-disable-next-line selector-max-specificity */
			.cog-button--primary {
				background: var(--red);
				border: none;
				text-transform: uppercase;
			}

			/* stylelint-disable-next-line selector-max-specificity */
			.cog-checkable__item {
				--font-lato: 'Lato';
				font-family: var(--font-lato);
			}

			/* stylelint-disable-next-line selector-max-specificity */
			.cog-loader {
				color: var(--blue);
			}

			/* stylelint-disable-next-line selector-max-specificity */
			.cog-content {
				padding: 10px;
				padding-top: 0;

				/* stylelint-disable-next-line selector-max-specificity */
				p {
					font-size: 18px;
				}
			}

			/* stylelint-disable-next-line selector-max-specificity */
			.cog-confirmation__documents {
				display: none;
			}
		}
	}

	&-cognito {
		position: fixed;
		bottom: 0;
		z-index: $z1;
		width: 100%;
		height: 125px;
		background: white;
		border-top: 2px solid var(--blue);
		padding: 0 $space__2 $space__2;

		@media (max-height: 880px) {
			position: relative;
		}

		&__circle {
			position: absolute;
			top: -53px;
			transform: translateX(-50%);
		}

		:root &__logo {
			position: relative;
			max-width: 150px;
			margin-top: -55px;
			margin-bottom: -20px;
		}
	}
}
</style>
